import type { Organization } from '../models'
import { useTracking } from '@ha/components-v3'
import { useUserStore } from '@/store/user.store'

export default () => {
  const userStore = useUserStore()
  const { tracking } = useTracking()

  const trackIdentify = () => {
    if (userStore.profile?.userId)
      tracking?.identify(userStore.profile?.userId, {
        userId: userStore.profile?.userId,
        email: userStore.profile?.email,
        firstName: userStore.profile?.firstName,
        lastName: userStore.profile?.lastName,
        lastSignInDate: userStore.profile?.lastSignInDate
      })
  }

  interface DataSegment {
    organization_slug?: string
    update_type?: Array<string | boolean>
  }
  const trackUpdateOrganization = (slug, organization, payload) => {
    let dataSegment: DataSegment = {
      organization_slug: slug,
      update_type: []
    }

    Object.entries(payload).forEach(([key, _value]: [key: string, _value: unknown]) => {
      if (
        organization[key as keyof Organization] !== undefined &&
        payload[key] !== undefined &&
        organization[key as keyof Organization] !== payload[key] &&
        (dataSegment.update_type != null)
      ) {
        dataSegment?.update_type.push(key)
      }
    })

    if (!!dataSegment.update_type && dataSegment.update_type.length > 0) {
      tracking?.track('Organization Page Updated', dataSegment)
    }
    dataSegment = {}
  }

  return {
    trackIdentify,
    trackUpdateOrganization
  }
}
